import React from 'react';
import styled from 'styled-components';
import { CellText } from '@increasecard/typed-components';

function getPalette(variant) {
  switch (variant) {
    case 'press':
      return {
        box: {
          bgColor: ({ theme }) => theme.colorsNew.increase.light,
          color: ({ theme }) => theme.colorsNew.gray.black,
        },
        icon: {
          bgColor: ({ theme }) => theme.colorsNew.increase.regular,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.increase.light30,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        title: {
          extraStyles: ({ theme }) =>
            `color: ${theme.colorsNew.increase.regular};`,
        },
      };
    case 'enterprise':
      return {
        box: {
          bgColor: ({ theme }) =>
            `linear-gradient(180deg, ${theme.colorsNew.gray.black} 0%, ${theme.colorsNew.gray.blackLight} 100%)`,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        icon: {
          bgColor: ({ theme }) => theme.colorsNew.gray.blackMedium,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.gray.blackMedium,
          color: ({ theme }) => theme.colorsNew.increase.accent,
        },
        title: {
          extraStyles: 'font-style: italic;',
        },
      };
    case 'support':
      return {
        box: {
          bgColor: ({ theme }) =>
            `linear-gradient(180deg, ${theme.colorsNew.increase.regular} 0%, ${theme.colorsNew.increase.light30} 100%)`,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        icon: {
          bgColor: ({ theme }) => theme.colorsNew.increase.dark30,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.increase.dark30,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
      };
    case 'saas':
    default:
      return {
        box: {
          bgColor: ({ theme }) =>
            `linear-gradient(180deg, ${theme.colorsNew.increase.dark} 0%, ${theme.colorsNew.increase.dark70} 100%)`,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        icon: {
          bgColor: ({ theme }) => theme.colorsNew.increase.regular,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.increase.dark30,
          color: ({ theme }) => theme.colorsNew.increase.accent,
        },
      };
  }
}

const Wrapper = styled.div`
  position: relative;
  border-radius: 22px;
  background: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${({ color }) => color};
  margin-top: calc(72px / 2);
  padding-top: calc(72px / 2);
  height: 208px;
`;
const Icon = styled.div`
  border-radius: 50%;
  background: ${({ bgColor }) => bgColor};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(-72px / 2);
  height: 72px;
  width: 72px;
  left: calc(50% - 72px / 2);
`;
const Title = styled.div`
  font-size: 26px;
  line-height: 31px;
  font-weight: bold;
  margin: 0 8px 8px;
  ${({ extraStyles }) => extraStyles}
`;
const Text = styled(CellText)`
  padding: 0 16px;
`;
const Cta = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  text-transform: uppercase;
  font-weight: bold;
  height: 40px;
  border-radius: 0 0 22px 22px;
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export function ContactOption({ title, text, icon, cta, variant }) {
  const palette = getPalette(variant);
  return (
    <Wrapper {...palette.box}>
      <Icon {...palette.icon}>
        {icon && (
          <img alt={icon.alternativeText} src={icon.imageSharp.publicURL} />
        )}
      </Icon>
      <TextWrapper>
        <Title {...palette.title}>{title}</Title>
        <Text>{text}</Text>
      </TextWrapper>
      <Cta href={cta.href} {...palette.cta}>
        {cta.text}
      </Cta>
    </Wrapper>
  );
}
