import React from 'react';
import { Layout } from '../layout/Layout';
import { ContactHeroSection } from '../pageSections/contacto/ContactHeroSection';
import { graphql } from 'gatsby';
import SEO from '../components/seo';

export default ({ data }) => {
  const {
    title,
    description,
    contactOptions,
    seoInfo,
  } = data.strapi.contactPage;
  return (
    <Layout>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      <ContactHeroSection
        title={title}
        subtitle={description}
        contactOptions={contactOptions}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      contactPage {
        seoInfo {
          pageTitle
          pageDescription
          keywords
        }
        title
        description
        contactOptions: contactOption {
          id
          title
          text
          cta {
            ...CTA
          }
          icon {
            alternativeText
            url
            imageSharp {
              publicURL
            }
          }
          variant
        }
      }
    }
  }
`;
