import React from 'react';
import { ContactOption } from './ContactOption';
import { BackgroundBase } from '../../backgrounds/BackgroundBase';
import { DisplayTitle } from '../../components/DisplayTitle';
import { Container as BaseContainer } from '../../components/Container';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { RoundedSquare } from '../../backgrounds/shapes/RoundedSquare';
import { MEDIUM } from '../../breakpoints';

export const IncreaseLogo = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.06557 32.98C6.1866 32.9777 4.38524 32.2303 3.05663 30.9016C1.72803 29.573 0.980674 27.7716 0.978516 25.8926L0.978516 8.066C0.980758 6.18717 1.72818 4.38594 3.0568 3.05749C4.38542 1.72903 6.18674 0.981851 8.06557 0.979858L25.8918 0.979858C27.7706 0.981768 29.5719 1.72894 30.9005 3.05741C32.2291 4.38589 32.9764 6.18717 32.9785 8.066V25.8926C32.9765 27.7716 32.2293 29.573 30.9007 30.9017C29.5721 32.2304 27.7707 32.9778 25.8918 32.98H8.06557ZM2.72189 8.066V25.8926C2.72338 27.3094 3.28684 28.6678 4.28863 29.6697C5.29042 30.6716 6.64874 31.2353 8.06557 31.2369H25.8918C27.3085 31.2352 28.6667 30.6715 29.6683 29.6696C30.6699 28.6676 31.2332 27.3093 31.2345 25.8926V8.066C31.233 6.64944 30.6697 5.29133 29.668 4.28964C28.6664 3.28795 27.3083 2.7245 25.8918 2.72292H8.06557C6.64891 2.72442 5.29068 3.28781 4.28889 4.28949C3.2871 5.29117 2.72355 6.64933 2.72189 8.066ZM22.3027 24.1847L16.9879 13.235L11.7647 24.184H8.33695L15.3177 9.77487H18.6391L25.6198 24.1847H22.3027Z"
      fill="#69F5A5"
    />
  </svg>
);

export const FolderIcon = () => (
  <svg
    width="33"
    height="27"
    viewBox="0 0 33 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M29.4141 4.13195H16.5582L13.3442 0.917969H3.70226C1.93457 0.917969 0.488281 2.36426 0.488281 4.13195V23.4158C0.488281 25.1835 1.93457 26.6298 3.70226 26.6298H29.4141C31.1818 26.6298 32.6281 25.1835 32.6281 23.4158V7.34593C32.6281 5.57824 31.1818 4.13195 29.4141 4.13195ZM26.2001 13.7739H22.9862V16.9879H26.2001V20.2019H22.9862V23.4158H19.7722V20.2019H22.9862V16.9879H19.7722V13.7739H22.9862V10.5599H19.7722V7.34593H22.9862V10.5599H26.2001V13.7739Z"
      fill="white"
    />
  </svg>
);

export const Container = styled(BaseContainer)`
  padding-top: 120px;
  text-align: center;
`;

export const Title = styled(DisplayTitle)`
  margin-bottom: 16px;
`;

export const Subtitle = styled(H3).attrs({ weight: 'normal' })`
  max-width: 24em;
  margin: 0 auto;
`;

export const ContactOptions = styled.ul`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 260px;
  justify-content: center;
  grid-template-rows: auto;
  margin-top: 24px;
  padding-bottom: 136px;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(4, 260px);
  }
`;

export const BottomBar = styled.div`
  width: 100%;
  height: 208px;
  background: ${({ theme }) => theme.colorsNew.increase.light70};
  position: absolute;
  bottom: 0;
  overflow: hidden;
`;

export const GiantSquare = styled(RoundedSquare).attrs({
  size: '808px',
  bgColor: theme => theme.colorsNew.increase.light50,
  rotation: '21deg',
})`
  position: absolute;
  bottom: -400px;
  right: 0;
`;

export const SmallSquare = styled(RoundedSquare).attrs({
  size: '72px',
  bgColor: theme => theme.colorsNew.increase.regular,
  rotation: '21deg',
})`
  position: absolute;
  bottom: calc(208px - 72px / 2);
  left: calc(16px + -72px / 2);
`;

export const TinySquare = styled(RoundedSquare).attrs({
  size: '25px',
  bgColor: theme => theme.colorsNew.increase.light70,
  rotation: '-30deg',
})`
  position: absolute;
  top: 64px;
  right: 20%;
  @media (min-width: ${MEDIUM}) {
    top: 160px;
  }
`;

export function ContactHeroSection({ title, subtitle, contactOptions }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      renderBackground={() => (
        <>
          <BottomBar>
            <GiantSquare />
          </BottomBar>
          <SmallSquare />
          <TinySquare />
        </>
      )}
    >
      <Container>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <ContactOptions>
          {contactOptions.map(({ id, ...rest }) => (
            <li key={id}>
              <ContactOption {...rest} />
            </li>
          ))}
        </ContactOptions>
      </Container>
    </BackgroundBase>
  );
}
